import React from 'react';

// import csssssss
import './About.css';

function About() {
    return (
        <div className="AboutContainer">
            <div className="AboutContainerWrapper">
                <div className="AboutTitel">
                    <h1>Mees Carels</h1>
                </div>
                <div className="PersonalSkills">
                    <h3>Front-end</h3>
                    <ul>
                        <li>HTML <span className="IntermediateLevel">Intermediate</span></li>
                        <li>CSS <span className="IntermediateLevel">Intermediate</span></li>
                        <li>Javascript <span className="IntermediateLevel">Intermediate</span></li>
                        <li>SwiftUI <span className="BeginnerLevel">Beginner</span></li>
                    </ul>
                    <h3>Back-end</h3>
                    <ul>
                        <li>PHP <span className="IntermediateLevel">Intermediate</span></li>
                        <li>SQL <span className="IntermediateLevel">Intermediate</span></li>
                        <li>Swift <span className="BeginnerLevel">Beginner</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default About;