import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import './App.css';
import './Menu.css';
import 'aos/dist/aos.css';

import Menu from './Menu';
import Home from './Home';
import About from './About';
import Portfolio from './Portfolio';
import AOS from 'aos';

function App() {
    AOS.init()
  return (
    <Router>
        <Menu />
      <Switch>
        <Route path="/" exact component={() => <Home />} />
        <Route path="/About" exact component={() => <About />} />
        <Route path="/Portfolio" exact component={() => <Portfolio />} />
      </Switch>
    </Router>
  )
}

export default App;
