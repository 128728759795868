import React from 'react';

// import csssssss
import './Portfolio.css';

// import Imagesss
import project1Logo from './Images/PG-Logo-2021-Groot-gold.svg';

import project2Logo from './Images/Caas_jpg.jpg';

// import componentsss

function Portfolio() {
    return (
        <div className="PortfolioContainer">
            <div className="PortfolioTitel" > 
                <h1>
                    Work
                </h1>
            </div>

            <div className="PortfolioInfo">
                <div className="PortfolioProjectHeader">
                    <img src={project1Logo} alt="Professor Grunschnabel" /><br/>
                    <div className="PortfolioBedrijfsInfo">
                        <p>
                            Bij Professor Grunschnabel ben ik begonnen als productie medewerker en kon ik door groeien en had ik de kans om de website te produceren. <br/>
                            <br/>
                            Naast mijn werk was ik zelf al veel aan het hobbyen met programmeren. Dit kon ik hier mooi in praktijk brengen waar ik ontzettend veel van geleerd heb. 
                            <br/>
                            <button>View Details</button>
                        </p>
                    </div>
                </div>
                <div className="PortfolioWebIframe">
                    <iframe src="https://grunschnabel.com" />
                </div>
            </div>
            <hr/>
            <div className="PortfolioInfo">
                <div className="PortfolioProjectHeader">
                    <img src={project2Logo} alt="Caas Architecten" /><br/>
                    <div className="PortfolioBedrijfsInfo">
                        <p>
                        Bij Professor Grunschnabel ben ik begonnen als productie medewerker en kon ik door groeien en had ik de kans om de website te produceren. <br/>
                        <br/>
                        Naast mijn werk was ik zelf al veel aan het hobbyen met programmeren. Dit kon ik hier mooi in praktijk brengen waar ik ontzettend veel van geleerd heb. 
                        </p>
                    </div>
                </div>
                
                <div className="PortfolioWebIframe">
                    <iframe src="https://caasarchitecten.nl" />
                </div>
            </div>
        </div>
    )
}

export default Portfolio;