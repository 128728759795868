import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';

// import cssssseesss
import './Menu.css';

//import imagess
import ProfielFoto from './Images/IMG_2278.png';

function Menu() {
    return (
        <div className="MainMenuContainer">
            <div className="MainMenuList">
                <Link to="/" className="MainMenuProfielFoto"><img src={ProfielFoto} alt="Mees Carels"/></Link>
                <Link to="/About"><div className="MainMenuAbout"><h3>About</h3></div></Link>
                <Link to="/Portfolio"><div className="MainMenuPortfolio"><h3>Work</h3></div></Link>
            </div>
        </div>
    )
}

export default Menu;