import React from 'react';

// import csssssss
import './Home.css';

function Home() {
    return (
        <div className="HomepageContainer">
            <div className="HomepageWelcomeMessage">
                <div className="HomepageTitel">
                    <h1><b>Hi!</b></h1>
                    <p>
                        Welcome on my personal website.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Home;